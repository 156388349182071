import classNames from "classnames";
import React from 'react';
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import { ImageLoadManager } from "../../../../logic/imageRepository/ImageLoadManager";
import { IRect } from "../../../../interfaces/IRect";
import { ISize } from "../../../../interfaces/ISize";
import { ImageRepository } from "../../../../logic/imageRepository/ImageRepository";
import { AppState } from "../../../../store";
import { updateImageDataById,updateImageData } from "../../../../store/labels/actionCreators";
import { ImageData } from "../../../../store/labels/types";
import { FileUtil } from "../../../../utils/FileUtil";
import { RectUtil } from "../../../../utils/RectUtil";
import './ImagePreview.scss';
import { CSSHelper } from "../../../../logic/helpers/CSSHelper";
import {store} from "../../../../index";
import {ImageActions} from "../../../../logic/actions/ImageActions";
import { image } from "@tensorflow/tfjs";


var locale = localStorage.getItem("locale");
interface IProps {
    imageData: ImageData;
    imagesData: ImageData[];
    style: React.CSSProperties;
    size: ISize;
    isScrolling?: boolean;
    isChecked?: boolean;
    onClick?: () => any;
    isSelected?: boolean;
    updateImageDataById: (id: string, newImageData: ImageData) => any;
    updateImageData:()=>any;
    number:number;
}

interface IState {
    image: HTMLImageElement;
}

class ImagePreview extends React.Component<IProps, IState> {
    private isLoading: boolean = false;

    constructor(props) {
        super(props);

        this.state = {
            image: null,
        }
    }

    public componentDidMount(): void {
        ImageLoadManager.addAndRun(this.loadImage(this.props.imageData, this.props.isScrolling));
    }

    public delImg(e){
        if(this.props.imagesData.length>1){
            let arr = this.props.imagesData.filter(r=>r.id!=this.props.imageData.id)
            if(Number(localStorage.getItem("isSelectImage"))>=arr.length){
                localStorage.setItem("isSelectImage", "0");
                ImageActions.getImageByIndex(0)
            }
            // console.log(Number(localStorage.getItem("isSelectImage")),arr)
            store.dispatch(updateImageData(arr))
            e.stopPropagation()
        }else{
            alert(locale === "en" ? "Labels" : (locale === "zh-Hans" ? "请至少保留一张图片进行标记" : "请至少保留一张图片进行标记"))
        }
        
    };

    public componentWillUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): void {
        if (this.props.imageData.id !== nextProps.imageData.id) {
            if (nextProps.imageData.loadStatus) {
                ImageLoadManager.addAndRun(this.loadImage(nextProps.imageData, nextProps.isScrolling));
            }
            else {
                this.setState({ image: null });
            }
        }

        if (this.props.isScrolling && !nextProps.isScrolling) {
            ImageLoadManager.addAndRun(this.loadImage(nextProps.imageData, false));
        }
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        return (
            this.props.imageData.id !== nextProps.imageData.id ||
            this.state.image !== nextState.image ||
            this.props.isSelected !== nextProps.isSelected ||
            this.props.isChecked !== nextProps.isChecked
        )
    }

    private loadImage = async (imageData: ImageData, isScrolling: boolean) => {
        if (imageData.loadStatus) {
            const image = ImageRepository.getById(imageData.id);
            if (this.state.image !== image) {
                this.setState({ image });
            }
        }else if (!isScrolling || !this.isLoading) {
            this.isLoading = true;
            if(imageData.fileData){
                const saveLoadedImagePartial = (image: HTMLImageElement) => this.saveLoadedImage(image, imageData);
                FileUtil.loadImage(imageData.fileData,'')
                    .then((image: HTMLImageElement) => saveLoadedImagePartial(image))
                    .catch((error) => this.handleLoadImageError())
            }else{
                const saveLoadedImagePartials = (url:string) => this.saveLoadedImages(url, imageData);
                FileUtil.loadImage(null,imageData.url)
                    .then((url) => saveLoadedImagePartials(imageData.url))
                    .catch((error) => this.handleLoadImageError())
            }
        }
    };

    private saveLoadedImages = (url: string, imageData: ImageData) => {
        imageData.loadStatus = true;
        let image = new Image()
        image.src = url
        this.props.updateImageDataById(imageData.id, imageData);
        ImageRepository.storeImage(imageData.id, image);
        if (imageData.id === this.props.imageData.id) {
            this.setState({ image });
            this.isLoading = false;
        }
    };

    private saveLoadedImage = (image: HTMLImageElement, imageData: ImageData) => {
        imageData.loadStatus = true;
        this.props.updateImageDataById(imageData.id, imageData);
        ImageRepository.storeImage(imageData.id, image);
        if (imageData.id === this.props.imageData.id) {
            this.setState({ image });
            this.isLoading = false;
        }
    };

    private getStyle = () => {
        const { size } = this.props;

        const containerRect: IRect = {
            x: 0.15 * size.width,
            y: 0.15 * size.height,
            width: 0.7 * size.width,
            height: 0.7 * size.height
        };

        const imageRect: IRect = {
            x: 0,
            y: 0,
            width: (this.state.image && this.state.image.width) || 150,
            height: (this.state.image&&this.state.image.height) || 150
        };

        const imageRatio = RectUtil.getRatio(imageRect);
        const imagePosition: IRect = RectUtil.fitInsideRectWithRatio(containerRect, imageRatio);

        return {
            width: imagePosition.width,
            height: imagePosition.height,
            left: imagePosition.x,
            top: imagePosition.y
        }
    };

    private handleLoadImageError = () => { };

    private getClassName = () => {
        return classNames(
            "ImagePreview",
            {
                "selected": this.props.isSelected,
            }
        );
    };
    

    public render() {
        const {
            isChecked,
            style,
            onClick,
            imageData,
            number
        } = this.props;
        console.log(number)
        return (
            <div
                className={this.getClassName()}
                style={style}
                onClick={onClick ? onClick : undefined}
            >
                {(!!this.state.image) &&<span  style={{position: 'absolute',color:'#fff',top:8,left:8}} 
                            onClick={(e)=>this.delImg(e)}>{number+1}</span>}
                {(!!this.state.image) &&<span title={locale === "en" ? "Click delete image" : (locale === "zh-Hans" ? "点击删除图片" : "點擊刪除圖片")} style={{position: 'absolute',color:'#fff',top:8,right:8}} 
                            onClick={(e)=>this.delImg(e)}>X</span>}
                {(!!this.state.image||imageData.url) &&
                    [
                        <div
                            className="Foreground"
                            key={"Foreground"}
                            style={this.getStyle()}
                        >
                            <img
                                className="Image"
                                draggable={false}
                                src={(this.state.image&&this.state.image.src)||imageData.url}
                                alt={(this.state.image&&this.state.image.alt)||imageData.url}
                                style={{ ...this.getStyle(), left: 0, top: 0 }}
                            />
                            {isChecked && <img
                                className="CheckBox"
                                draggable={false}
                                src={"ico/ok.png"}
                                alt={"checkbox"}
                            />}
                        </div>,
                        <div
                            className="Background"
                            key={"Background"}
                            style={this.getStyle()}
                        />
                    ] 
                    // :
                    // <ClipLoader
                    //     size={30}
                    //     color={CSSHelper.getLeadingColor()}
                    //     loading={true}
                    // />
                }
            </div>)
    }
}

const mapDispatchToProps = {
    updateImageDataById,
    updateImageData
};

const mapStateToProps = (state: AppState) => ({
    imagesData: state.labels.imagesData,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImagePreview);